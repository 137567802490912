import React from "react";
import { StyledTopBar, StyledStart90 } from "../styled";
import {  useHistory } from "react-router-dom";
import { Formik } from "formik";
const Login = () => {
  let history = useHistory();

  const loginUser = async (data) => {
    const response = await fetch("https://coverx-api.herokuapp.com/api/v1/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();

    if (result && result?.token) {
      localStorage.setItem("token", result.token?.accessToken);
      localStorage.setItem("user", JSON.stringify(result.user));
      history.push("/cover");
    } else {
      alert(result.errors?.message);
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={(values) => {
        if (Object.keys(values).length) {
          loginUser(values);
        }
      }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <StyledTopBar>
          <div class="back-arrow">
            <img src={require("../images/left-arrow.svg").default} alt="" />
          </div>


          <div class="next-arrow">
            <img src={require("../images/right-arrow.svg").default} alt="" />
          </div>
            <div className="title">sign in</div>
          </StyledTopBar>

          <StyledStart90 className="white-back">
            <div className="full-width top15-p bottom10-p">
              <div className="image30">
                <img
                  src={require("../images/user1.svg").default}
                  alt=""
                  className="responsive"
                />
              </div>
            </div>

            <div className="full-width top15">
              <input
                type="email"
                name="email"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.email}
                className="textbox"
                placeholder="email"
              />
            </div>
            <div className="full-width top15">
              <input
                type="password"
                name="password"
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                value={props.values.password}
                className="textbox"
                placeholder="Password"
              />
            </div>

            <div className="full-width top15">
              <button type="submit" className="btn btn-red top15 thanks-btn">
                login
              </button>
            </div>
            <div className="full-width">
              <p className="hero-text">Forgot Password?</p>
            </div>
            <div className="logo-blue">
              <img
                src={require("../images/logo-blue.png").default}
                alt=""
                className="responsive"
              />
            </div>
          </StyledStart90>
        </form>
      )}
    </Formik>
  );
};

export default Login;
