import React from "react";
import { StyledTopBar, StyledStart90 } from "../styled";
import { Link, useHistory } from "react-router-dom";
import { Formik } from 'formik';
const Register = () => {
  let history = useHistory();

  const registerUser = async (data) => {
    console.log('data', data);
    const response = await fetch('https://coverx-api.herokuapp.com/api/v1/auth/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(data)
    })


    const result = await response.json()

    if(result && result?.token) {
      history.push("/login");
    } else {
      alert(result.errors?.message);
    }
  }

  return (
    <Formik
    initialValues={{
      name: '',
      surname: '',
      email: '',
      phone: '',
      password: ''
   }}
    onSubmit={(values) => {
      console.log('values veja', values);
      if(Object.keys(values).length) {
       registerUser(values)
      }
    }}
  >
    {props => (
         <form onSubmit={props.handleSubmit}>
           <StyledTopBar>
           <Link to="/step2">
             <div class="back-arrow">
               <img src={require("../images/left-arrow.svg").default} alt="" />
             </div>
           </Link>
           <Link to="/start">
             <div class="next-arrow">
               <img src={require("../images/right-arrow.svg").default} alt="" />
             </div>
           </Link>
        <div className="progress">
          <div className="title">Register</div>
        </div>
      </StyledTopBar>

      <StyledStart90 className="white-back">
        <div className="full-width top10-pm blue-back">
          <div className="image15">
            <img src={require("../images/user2.svg").default} alt="" className="responsive" />
          </div>
        </div>
        <div className="full-width blue-back">
          <p className="hero-text white-font">
          Fill in below
          </p>
        </div>
        <div className="full-width top15"></div>
        <div className="full-width top15"></div>
        <div className="full-width top15">
          <input
            type="text"
            name="name"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.name}
            className="textbox"
            placeholder="Name"
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="surname"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.surname}
            className="textbox"
            placeholder="Surname"
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="phone"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.phone}
            className="textbox"
            placeholder="Mobile Number"
          />
        </div>
        <div className="full-width top15">
          <input
            type="email"
            name="email"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.email}
            className="textbox"
            placeholder="email"
          />
        </div>
        <div className="full-width top15">
          <input
            type="password"
            name="password"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.password}
            className="textbox"
            placeholder="Password"
          />
        </div>
        <div className="full-width top15">
          <input
            type="password"
            name="confirm"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            // value={values.needs?.number_children}
            className="textbox"
            placeholder="Confirm Password"
          />
        </div>
        <div className="full-width top15">
        <button type="submit" className="btn btn-red thanks-btn top15">
            Create
          </button>
        </div>
        <div className="full-width">
          <p className="hero-text">
            Already a member? <Link to="/login">SIGN IN</Link>
          </p>
        </div>
      </StyledStart90>
         </form>
       )}
      </Formik>
  );
};

export default Register;
