import React from "react";
import { StyledTopBar2 } from "../styled";
import { Link } from "react-router-dom";

const StepThree = () => {
  return (
    <>
      <StyledTopBar2>
        <Link to="/step2">
          <div class="back-arrow">
            <img src={require("../images/left-arrow.svg").default} alt="" />
          </div>
        </Link>
        <Link to="/start">
          <div class="next-arrow">
            <img src={require("../images/right-arrow.svg").default} alt="" />
          </div>
        </Link>

      </StyledTopBar2>

      <div class="stage-hero step1-bg">
      <img src={require("../images/docs.svg").default} alt="" class="hero-img" />
      <div class="progress top15-p">
        <div class="stage-bar"></div>
        <div class="stage-bar"></div>
        <div class="stage-bar active-bar"></div>
      </div>
      <div className="bottom-spacer">
      </div>
      <div className="bottom-spacer">
      </div>
      </div>
      <p class="hero-text bottom-zero">
      Store your policy documents on your profile for easy access when you require them.
      </p>
      <div class="logo-blue">
        <img src={require("../images/logo.svg").default} alt="" class="responsive" />
      </div>
      <div class="full-width">
        <Link to="/start" class="btn btn-blue top15">
          next
        </Link>
      </div>
    </>
  );
};

export default StepThree;
