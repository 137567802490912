import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { StyledTopBar2 } from '../styled'
import {  useHistory } from "react-router-dom";

const Welcome = () => {
  const [userName, setUserName] = useState('')
  let history = useHistory();

  useEffect(() => {
    const userData = localStorage.getItem('user')
    const {name} = JSON.parse(userData)

    setUserName(name)
  }, [])

  const logOutUser = (e) => {
    e.preventDefault()
    localStorage.clear()
    history.push("/");
  }

  return (
    <>
      <div className="screen">
        <StyledTopBar2>
          <Link to="/welcome">
            <div className="back-arrow">
              <img src={require("../images/left-arrow.svg").default} alt="" />
            </div>
          </Link>
          <Link to="/welcome">
            <div className="next-arrow">
              <img src={require("../images/right-arrow.svg").default} alt="" />
            </div>
          </Link>
          <div className="progress">
            <div className="title">welcome</div>
          </div>
        </StyledTopBar2>
        <div className="hero-90 blue-background">
        <div class="progress top15">
          <img src={require("../images/user1.svg").default} alt="" />
        </div>
          <p className="hero-text" style={{color: "#555"}}>
            Good day {userName},
            <br />
            what can we do for you today?
          </p>
          <div className="width-15pad go-left">
          <a href="/carpage">
            <div className="home-icon alert-neutral">
            <div className="price">R972.00</div>
              <div className="icon-house">
                <div className="icon">
                  <img src={require("../images/car-home.svg").default} alt="" className="unread" />
                </div>
              </div>
              <div className="home-item">
              <div className="insured-title">
              <span className="bolda">car insurance</span><br></br> 2012 Mini coop...
              </div>
              <div className="insured-status color-neutral">
              Up to date
              </div>
              </div>
              <div className="icon-house">
                <div className="icon">
                  <img src={require("../images/go.svg").default} alt="" className="unread" />
                </div>
              </div>
            </div>
            </a>
            <a href="/carpage">
            <div className="home-icon alert-neutral">
            <div className="price">R1,849.00</div>
              <div className="icon-house">
                <div className="icon">
                  <img src={require("../images/car-home.svg").default} alt="" className="unread" />
                </div>
              </div>
              <div className="home-item">
              <div className="insured-title">
              <span className="bolda">car insurance</span><br></br> 2017 Mercede...
              </div>
              <div className="insured-status color-neutral">
              Up to date
              </div>
              </div>
              <div className="icon-house">
                <div className="icon">
                  <img src={require("../images/go.svg").default} alt="" className="unread" />
                </div>
              </div>
            </div>
            </a>
            <a href="/housepage">
            <div className="home-icon alert-caution">
            <div className="price">R645.00</div>
              <div className="icon-house">
                <div className="icon">
                  <img src={require("../images/house-home.svg").default} alt="" className="unread" />
                </div>
              </div>
              <div className="home-item">
              <div className="insured-title">
              <span className="bolda">Home insurance</span><br></br> 36 scaffell rd fl...
              </div>
              <div className="insured-status color-caution">
              Accept quotation
              </div>
              </div>
              <div className="icon-house">
                <div className="icon">
                  <img src={require("../images/go.svg").default} alt="" className="unread" />
                </div>
              </div>
            </div>
            </a>
            <a href="/funeralpage">
            <div className="home-icon alert-bad">
            <div className="price">R352.00</div>
              <div className="icon-house">
                <div className="icon">
                  <img src={require("../images/fun-home.svg").default} alt="" className="unread" />
                </div>
              </div>
              <div className="home-item">
              <div className="insured-title">
              <span className="bolda">Funeral Cover</span><br></br>5 members
              </div>
              <div className="insured-status color-bad">
              Paymant Due
              </div>
              </div>
              <div className="icon-house">
                <div className="icon">
                  <img src={require("../images/go.svg").default} alt="" className="unread" />
                </div>
              </div>
            </div>
            </a>
            <div className="home-icon alert-neutral">
              <div className="icon-house">
                <div className="icon">
                  <img src={require("../images/new-home.svg").default} alt="" className="unread" />
                </div>
              </div>
              <div className="home-item">
              <div className="insured-title">
              <br></br>Add New Services
              </div>
              <div className="insured-status color-bad">

              </div>
              </div>
              <div className="icon-house">
                <div className="icon">
                  <img src={require("../images/add.svg").default} alt="" className="unread" />
                </div>
              </div>
            </div>


          </div>
          <div  className="width-15pad top15">
          <div className="full-width top15">
            <Link to="/#" className="btn btn-invert full-width" style={{color:"#fff", border: "none", background: "#d20f0f"}}>R1,800.00 Due, pay now</Link>
          </div>
          <div className="full-width top15">
            <Link to="/#" onClick={logOutUser} className="btn btn-invert full-width" style={{color:"#000", border: "none"}}>Logout</Link>
          </div>
          <div className="bottom-spacer">
          </div>
          </div>

          <div className="footer">
            <div className="footer-icon">
              <img src={require("../images/home.svg").default} alt="" className="footer-link" />
            </div>
            <div className="footer-icon">
              <img src={require("../images/settings.svg").default} alt="" className="footer-link" />
            </div>
            <div className="footer-icon">
              <img src={require("../images/support.svg").default} alt="" className="footer-link" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
