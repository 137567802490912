import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const FuneralPage = () => {
  return (
    <div class="screen">
      <div class="geXTrK">

        <div class="back-arrow">
        <img src={require("./images/left-arrow.svg").default} alt="" />
        </div>

        <div class="next-arrow">
          <img src={require("./images/right-arrow.svg").default} alt="" />
        </div>

        <div class="progress">
          <div class="title">home cover</div>
        </div>

      </div>
      <div class="hero-90 blue-background">

        <div class="progress">
        <img src={require("./images/fun-home.svg").default} alt="" />
        </div>
        <p
          class="hero-text"
          style={{margin:0, textTransform: "uppercase", fontWeight:700, color: "#4786fb"}}
        >
          5 members R250,000.00
        </p>

        <div class="full-width">

          <div class="left-text">Monthly Premium</div>
          <div class="right-text">R352.00</div>
          <div class="left-text">Payout</div>
          <div class="right-text">R50,000.00</div>
          <div class="left-text">Members</div>
          <div class="right-text">R5</div>
          <div class="left-text">Status</div>
          <div class="right-text">Active</div>

        </div>
        <div class="width-15pad top15 go-left">

          <div class="home-icon alert-neutral">

            <div class="icon-house">

              <div class="icon">
                <img src={require("./images/fun-claim.svg").default} alt="" class="unread" />
              </div>

            </div>

            <div class="home-item">
              <div class="insured-title" style={{marginTop: "10px"}}>
                <span class="bolda">Submit a Claim</span>
                <br />I have lost a loved one.
              </div>
              <div class="insured-status color-bad"></div>
            </div>
            <div class="icon-house">
              <div class="icon">
              <img src={require("./images/do-go.svg").default} alt="" class="unread" />

              </div>
            </div>
          </div>
          <div class="home-icon alert-neutral">
            <div class="icon-house">
              <div class="icon">
              <img src={require("./images/adjust.svg").default} alt="" class="unread" />

              </div>
            </div>
            <div class="home-item">
              <div class="insured-title">
                <span class="bolda">Adjust My Cover</span>
                <br />I want to add, remove or adjust items on my cover
              </div>
              <div class="insured-status color-bad"></div>
            </div>
            <div class="icon-house">
              <div class="icon">
              <img src={require("./images/do-go.svg").default} alt="" class="unread" />

              </div>
            </div>
          </div>
          <div class="home-icon alert-neutral">
            <div class="icon-house">
              <div class="icon">

                <img src={require("./images/pay.svg").default} alt="" class="unread" />
              </div>
            </div>
            <div class="home-item">
              <div class="insured-title">
                <span class="bolda">Make Payment</span>
                <br />I want to pay for my premium or any outstanding payments
              </div>
              <div class="insured-status color-bad"></div>
            </div>
            <div class="icon-house">
              <div class="icon">
              <img src={require("./images/do-go.svg").default} alt="" class="unread" />
                <img src="images/do-go.svg" alt="" class="unread" />
              </div>
            </div>
          </div>
          <div class="home-icon alert-neutral">
            <div class="icon-house">
              <div class="icon">
              <img src={require("./images/contact.svg").default} alt="" class="unread" />

              </div>
            </div>
            <div class="home-item">
              <div class="insured-title" style={{marginTop: "10px"}}>
                <span class="bolda">Contact my insurer</span>
                <br />I have an enquiry to make
              </div>
              <div class="insured-status color-bad"></div>
            </div>
            <div class="icon-house">
              <div class="icon">
                <img src={require("./images/do-go.svg").default} alt="" class="unread" />
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-spacer">
        </div>

        <div className="footer">
          <Link to="/welcome">
          <div className="footer-icon">
            <img src={require("./images/home.svg").default} alt="" className="footer-link" />
          </div>
          </Link>
          <div className="footer-icon">
            <img src={require("./images/settings.svg").default} alt="" className="footer-link" />
          </div>
          <div className="footer-icon">
            <img src={require("./images/support.svg").default} alt="" className="footer-link" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FuneralPage;
