import React from "react";
import { StyledScreen } from "./styled";
import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";

// Login
import UserLogin from "./user/login";
import UserRegister from "./user/register";

// Form Widget
import FormWidget from "./wizard";

import Start from "./start";
import Thanks from "./wizard/thanks";
import Welcome from "./home/welcome";
import Splash from "./home/splash";
import StepOne from "./home/stepOne";
import StepTwo from "./home/stepTwo";
import StepThree from "./home/stepThree";
import CarPage from "./carpage";
import HousePage from "./housepage";
import FuneralPage from "./funeralpage";
const fetchStoredUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const currentUser = fetchStoredUser();
      if (!currentUser?.id) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }
      // authorised so return component
      return <Component {...props} />;
    }}
  />
);

export default function App() {
  return (
    <Router>
      <Switch>
        <StyledScreen>
          {/* Base */}
          {/* <Redirect exact from="/" to="/splash" /> */}
          <Route exact path="/" component={Splash} />
          <Route exact path="/step1" component={StepOne} />
          <Route exact path="/step2" component={StepTwo} />
          <Route exact path="/step3" component={StepThree} />

          <Route exact path="/start" component={Start} />

          {/* user */}
          <Route exact path="/login" component={UserLogin} />
          <Route exact path="/register" component={UserRegister} />

          {/* Widget */}
          <PrivateRoute exact path="/cover" component={FormWidget} />

          {/* thanks */}
          <PrivateRoute exact path="/thanks" component={Thanks} />

          <PrivateRoute exact path="/welcome" component={Welcome} />
          <PrivateRoute exact path="/carpage" component={CarPage} />
          <PrivateRoute exact path="/housepage" component={HousePage} />
          <PrivateRoute exact path="/funeralpage" component={FuneralPage} />
        </StyledScreen>
      </Switch>
    </Router>
  );
}
