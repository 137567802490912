import React from "react";
import { StyledTopBar, StyledStart90 } from "../styled";

const NeedsAnalysis = ({ handleChange, handleBlur, values }) => {
  return (
    <>
      <StyledTopBar>

        <div class="back-arrow">
          <img src={require("../images/left-arrow.svg").default} alt="" />
        </div>


        <div class="next-arrow">
          <img src={require("../images/right-arrow.svg").default} alt="" />
        </div>

        <div className="title">Funeral cover</div>
      </StyledTopBar>

      <StyledStart90 className="white-back">

        <div className="full-width blue-back">
        <div className="progress top15">
          <img src={require("../images/funeral.svg").default} alt="" />
        </div>
        </div>
        <div className="full-width blue-back">
          <p className="hero-text white-font">Tell us about your funeral cover needs.</p>
        </div>
        <div className="full-width top15"></div>
        <div className="full-width top15"></div>
        <div className="full-width top15">
          <input
            type="text"
            name="funeral_cover.number_people_covered"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.funeral_cover?.number_people_covered}
            className="textbox"
            placeholder="How many people covered?"
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="funeral_cover.person_1_name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.funeral_cover?.person_1_name}
            className="textbox"
            placeholder="Person 1 name"
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="funeral_cover.person_2_name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.funeral_cover?.person_2_name}
            className="textbox"
            placeholder="Person 2 name"
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="funeral_cover.person_3_name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.funeral_cover?.person_3_name}
            className="textbox"
            placeholder="Person 3 name"
          />
        </div>
        <div className="full-width top15">
          <input
            type="text"
            name="funeral_cover.person_4_name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.funeral_cover?.person_4_name}
            className="textbox"
            placeholder="Person 4 name"
          />
        </div>



        <div className="full-width">
          <button type="submit" className="btn btn-blue thanks-btn">
            Get Quote
          </button>
        </div>
        <div className="bottom-spacer">
        </div>
      </StyledStart90>
    </>
  );
};

export default NeedsAnalysis;
