import styled, { css } from "styled-components";
import startHero from '../images/start-hero.png';
import splashImg from '../images/splash.png'

export const StyledSplashScreen = styled.div`
  max-width: 500px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  align-items: center;
  background: rgb(71,134,251);
`

const SharedStartStyles = css`
  width: 100%;
  float: left;
  height: 100%;
  position: relative;
  background-image:url(${startHero});
  background-size: cover;
`

export const StyledScreen  = styled.div`
  background-color: #efefef;
  position: fixed;
left: 0;
right: 0;
bottom: 0;
top: 0;
`

export const StyledTopBar = styled.div`
height: 7.5%;
width: 100%;
float: left;
display: flex;
align-items: center;
position: fixed;
z-index: 5;
background: rgb(71,134,251);
left: 0;
right: 0;
`

export const StyledTopBar2 = styled.div`
height: 7.5%;
width: 100%;
float: left;
display: flex;
align-items: center;
z-index: 5;
position: fixed;
background: rgb(71,134,251);
left: 0;
right: 0;

`

export const StyledStart90 = styled.div`
  width: 100%;
  float: left;
  position: fixed;
  top: 7.5%;
  bottom: 0;
  overflow: scroll;
`

export const StyledStartHero = styled.div`
  background-image:url(${startHero});
  background-size: cover;
`

export const StyledStartCombined = styled.div`
  ${SharedStartStyles}
`
